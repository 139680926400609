<template>
  <b-card header="true" header-tag="header">
    <div
      slot="header"
      class="d-flex justify-content-between align-items-center"
    >
      <p class="mb-0 d-inline-block align-middle">
        <b>{{ header }}</b>
      </p>
    </div>
    <b-row v-for="(field, index) in fields" :key="index">
      <div class="col-md-4 border-bottom">
        {{ field.label }}
      </div>
      <div
        class="col-md-8 border-bottom"
        v-if="field.value === 'deferred_status'"
      >
        {{ totalDeferred() === 0 ? "CHIUSO" : "APERTO" }}
      </div>
      <div class="col-md-8 border-bottom" v-if="field.value === 'gross'">
        {{ toLocaleCurrency(data.gross) }}
      </div>
      <div class="col-md-8 border-bottom" v-if="field.value === 'deferred_pos'">
        {{ toLocaleCurrency(totalDeferredPos()) }}
      </div>
      <div class="col-md-8 border-bottom" v-if="field.value === 'deferred_neg'">
        {{ toLocaleCurrency(-(totalDeferredNeg() + toRebate())) }}
      </div>
      <div class="col-md-8 border-bottom" v-if="field.value === 'rebate'">
        {{ toLocaleCurrency(toRebate()) }}
      </div>
      <div class="col-md-8 border-bottom" v-if="field.value === 'total_gross'">
        {{ toLocaleCurrency(totalDeferred()) }}
      </div>
    </b-row>
  </b-card>
</template>

<script>
import { toLocaleCurrency } from "@/utils/strings";

export default {
  data() {
    return {};
  },
  props: {
    header: String,
    repository: String,
    fields: {
      type: Array,
    },
    beForm: {
      type: Object,
    },
    data: {
      type: Object,
    },
    labels: {
      type: Object,
    },
  },
  methods: {
    toLocaleCurrency,
    totalDeferred() {
      return parseFloat(
        this.data.entry_details
          .filter((el) => el.treasury.code == "SS")
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    totalDeferredPos() {
      return parseFloat(
        this.data.entry_details
          .filter((el) => el.treasury.code == "SS" && el.gross > 0)
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    totalDeferredNeg() {
      return parseFloat(
        this.data.entry_details
          .filter((el) => el.treasury.code == "SS" && el.gross < 0)
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    toRebate() {
      let deferredIds = this.getDeferredIds(this.data);
      for (const id of deferredIds) {
        let deferredEntryDetail = this.data.entry_details.find(
          (e) => e.id === id
        );
        if (deferredEntryDetail) {
          const found = deferredEntryDetail.entry_details.find(
            (e) => e.treasury.code === "AB"
          );
          if (found) {
            return found.gross;
          }
        }
      }
      return 0;
    },
    getDeferredIds(item) {
      let ret = item.entry_details
        .filter((e) => e.treasury.code === "SS" && parseFloat(e.gross) < 0)
        .map((e) => e.id);
      return ret;
    },
  },
  computed: {},
};
</script>
<style scoped>
.card {
  border: none;
}
.col-md-4.border-bottom {
  text-transform: uppercase;
  color: rgb(63, 60, 60);
  font-size: bold;
}
#informazioni {
  margin-right: 560px;
}
</style>
