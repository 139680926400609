<template>
  <div class="mt-1 mx-3">
    <deferred></deferred>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Deferred from "@/components/accounting/deferred/index.vue";

export default {
  props: {},
  components: { Deferred },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Contabilità",
      path: "",
      level: 1,
    });
    this.notifyQueue({ text: "Sospesi", path: "module.CONT.SOSP", level: 2 });
  },
};
</script>
