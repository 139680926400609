<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in detailFields"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-card header="true" header-tag="header" class="mt-2">
            <div
              slot="header"
              class="d-flex justify-content-between align-items-center"
            >
              <p class="mb-0 d-inline-block align-middle">
                <b>Recupero Acconto</b>
              </p>
            </div>
            <div v-if="details.gross + details.reduced != 0">
              <b-row>
                <div class="col-md-4">
                  <base-select
                    vid="cash_reference_id"
                    name="causale"
                    label="Causale"
                    v-model="form.cash_reference_id"
                    :options="cash_references_opt"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    @input="onCashReferenceInput"
                    :rules="{ required: true }"
                    :preselectFirst="true"
                  />
                </div>
                <div class="col-md-4">
                  <base-datepicker
                    vid="valuta_da"
                    name="Data Recupero Acconto"
                    label="Data Recupero Acconto"
                    v-model="form.cash_time"
                    :min="minDate"
                    @select="onDetailDateSelect"
                    :rules="{ required: true }"
                  />
                </div>
                <div class="col-md-4">
                  <base-select
                    vid="broker_id"
                    name="produttore"
                    label="Produttore"
                    v-model="form.broker_id"
                    :options="getSalesmen()"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    :rules="has_broker"
                  />
                </div>
                <div class="col-md-4">
                  <base-currency
                    name="importo"
                    vid="gross"
                    label="Importo"
                    v-model="form.gross"
                    :options="{
                      currency: 'EUR',
                      locale: 'it-IT',
                      precision: 2,
                    }"
                    :rules="{
                      required: true,
                      compare_number: { otherValue: '0', operator: '>' },
                    }"
                  />
                </div>
                <div class="col-md-4">
                  <base-select
                    vid="treasury_id"
                    name="conto"
                    label="Conto"
                    v-model="form.treasury_id"
                    :options="treasuries_opt"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    :rules="{ required: true }"
                  />
                </div>
                <div class="col-md-4">
                  <input type="hidden" name="title" :value="form.title" />
                </div>
              </b-row>

              <b-button
                type="button"
                variant="lisaweb"
                class="mb-2"
                @click="handleSubmit(onSubmit)"
                :disabled="invalid"
                >Recupera</b-button
              >
            </div>
            <div v-else>
              <b>Acconto Chiuso</b>
            </div>
          </b-card>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import DetailsCard from "@/components/DetailsCard";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toLocaleCurrency } from "@/utils/strings";
import BaseIcon from "@/components/BaseIcon";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
// import BaseInput from "@/components/form/BaseInput";
import BaseCurrency from "@/components/form/BaseCurrency";
import { toLocaleDate } from "@/utils/dates";
import moment from "moment";
moment.locale("it");
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShowMixin],
  name: "General",
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    DetailsCard,
    BaseIcon,
    BaseDatepicker,
    BaseSelect,
    // BaseInput,
    BaseCurrency,
  },
  data() {
    return {
      details: null,
      repository: "cash_entry",
      allRepositories: ["cash_entry"],
      cash_references_opt: [],
      DEPOSITED_CASH_REFERENCE: 3,
      has_broker: {},
      treasuries_opt: [],
      mandatoryFields: {},
      isLoading: true,
      generalLabels: null,
      defaultLabels: {
        contacts: {},
      },
      detailFields: {
        scheda_contatto: {
          header: "Scheda Acconto",
          edit: false,
          repository: "cash_entry",
          fields: [
            {
              label: this.getDictionary("description", "cash_entry"),
              value: "title",
            },
            {
              label: this.getDictionary("cash_time", "cash_entry"),
              type: "datepicker",
              value: "cash_time",
            },
            // {
            //   label: "Causale",
            //   value: "cash_reference_id",
            //   virtual: (value) => this.getCurrentCashReferences(value),
            // },
            {
              label: this.getDictionary("Acconto"),
              type: "currency",
              value: "gross",
            },
            {
              label: this.getDictionary("Acconto Incassato"),
              type: "currency",
              virtual: (item) => {
                return toLocaleCurrency(Math.abs(item?.reduced));
              },
            },
            {
              label: this.getDictionary("Residuo"),
              value: "",
              virtual: (item) => {
                return toLocaleCurrency(Math.abs(item?.gross + item?.reduced));
              },
            },
            {
              label: this.getDictionary("Stato Acconto"),
              value: "",
              virtual: (item) => {
                if (item?.reduced === null) return "APERTO";
                if (item?.gross + item?.reduced === 0) return "CHIUSO";
                return "PARZIALMENTE APERTO";
              },
            },
          ],
        },
      },
      form: {
        cash_reference_id: null,
        broker_id: null,
        title: null,
        gross: null,
        treasury_id: null,
        cash_entry_id: this.resourceId,
        cash_time: moment().format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    ...mapGetters("auth", {
      getCashReferences: "cash_references",
      getSalesmen: "salesmen",
      getTreasuries: "allTreasuries",
    }),
    fetch() {
      const queryString = `byAttribute[id]=${this.resourceId}&byDeposited=both`;
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.index(queryString)
        .then((response) => {
          const data = response.data.data;
          this.details = data[0];
          this.form.title = this.details?.title;
          this.form.broker_id = this.details?.broker_id;
          this.form.treasury_id = this.details?.treasury_id;
          this.form.gross = toLocaleCurrency(
            Math.abs(
              parseFloat(this.details?.gross + this.details?.reduced).toFixed(2)
            )
          );
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    onDetailDateSelect(date) {
      // Typed date validity
      if (moment(date, "DD/MM/YYYY").isBefore(this.minDate)) {
        let errMsg = `La data non può essere uguale o precedente a ${toLocaleDate(
          this.minDate
        )}`;
        this.$showSnackbar({
          preset: "info",
          text: `${errMsg}`,
        });
        this.form.cash_time = null;
      }
    },
    onSubmit() {
      this.isLoading = true;
      // if (
      //   Math.abs(this.form.gross) <=
      //   this.details.gross + this.details.reduced
      // ) {
      //   const payload = {
      //     cash_reference_id: this.form.cash_reference_id,
      //     detail_date: this.form.detail_date,
      //     title: this.form.title,
      //     gross: -Math.abs(this.form.gross),
      //     treasury_id: this.form.treasury_id,
      //     cash_entry_id: this.resourceId,
      //     cash_time: toLocaleDate(this.form.cash_time, "YYYY-MM-DD HH:mm:ss"),
      //   };
      let form_gross = this.form.gross;
      if (form_gross.toString().indexOf("€") >= 0) {
        form_gross.toString().replace(/[^\d.,]/g, ""); // Convert number to string, remove non-numeric characters except dots and commas
        form_gross = form_gross.replace(".", ""); // Replace commas with dots for correct parsing
        form_gross = form_gross.replace(",", "."); // Replace commas with dots for correct parsing
      }
      form_gross = Math.abs(parseFloat(form_gross).toFixed(2)); // Parse the string to a floating-point number
      let form_remaining = Math.abs(
        parseFloat(this.details.gross + this.details.reduced).toFixed(2)
      );
      if (form_gross <= form_remaining) {
        const payload = {
          cash_reference_id: this.form.cash_reference_id,
          detail_date: this.form.detail_date,
          title: this.form.title,
          gross: -form_gross,
          treasury_id: this.form.treasury_id,
          cash_entry_id: this.resourceId,
          cash_time: toLocaleDate(this.form.cash_time, "YYYY-MM-DD HH:mm:ss"),
        };
        if (this.form.broker_id) payload["broker_id"] = this.form.broker_id;
        const Repo = RepositoryFactory.get(this.repository);
        Repo.store(payload)
          .then(() => {
            this.fetch().then(() => {
              this.$emit("fetch");
              this.isLoading = false;
              this.$showSnackbar({
                preset: "success",
                text: "Acconto Recuperato con successo",
              });
            });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.isLoading = false;
          });
      } else {
        this.$showSnackbar({
          preset: "success",
          text: `L'importo inserito non deve superare il residuo disponibile`,
        });
        this.isLoading = false;
      }
    },
    onCashReferenceInput(value) {
      if (value) {
        const found = this.cash_references_opt.find(
          (cash_reference) => cash_reference.value === value
        );
        if (found) {
          this.has_broker =
            found.has_broker.value === "Y" ? { required: true } : {};

          const ENTRY_IN_RULE = {
            required: true,
            compare_number: { otherValue: "0", operator: ">" },
          };
          const ENTRY_OUT_RULE = {
            required: true,
            compare_number: { otherValue: "0", operator: "<" },
          };
          this.gross_rule =
            found.entry_type.value === 0 ? ENTRY_IN_RULE : ENTRY_OUT_RULE;
        }
      } else {
        this.gross_rule = { required: true };
      }
    },
    // getCurrentCashReferences(value) {
    //   const cashReferences = this.getCashReferences();
    //   const found = cashReferences.find(
    //     (cash_reference) => cash_reference.value == value.cash_reference_id
    //   );
    //   return `${found?.text} - ${found?.description}`;
    // },
  },
  computed: {
    minDate() {
      return moment(this.details.cash_time).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.isLoading = true;
    this.cash_references_opt = [];
    let cashReferences = this.getCashReferences();
    cashReferences.sort((a, b) => a.text - b.text);
    cashReferences.forEach((el) => {
      this.cash_references_opt.push({
        text: `${el.text} - ${el.description}`,
        has_broker: el.has_broker,
        entry_type: el.entry_type,
        reference_type: el.reference_type,
        value: el.value,
      });
    });
    this.cash_references_opt = this.cash_references_opt.filter(
      (cash_reference) =>
        cash_reference.reference_type.value == this.DEPOSITED_CASH_REFERENCE
    );
    this.treasuries_opt = this.getTreasuries().filter(
      (treasury) => treasury?.sector?.code == "UPN"
    );
    this.fetch();
  },
};
</script>
