<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <details-card
              v-for="(item, index) in detailFields"
              :key="index"
              :header="item.header"
              :edit="item.edit || false"
              :fields="item.fields"
              :beForm="beForm"
              :data="details"
              :repository="item.repository"
            >
            </details-card>

            <book-entry-registry-details-card
              v-for="(item, i) in registryDetailsFields"
              :key="i"
              :header="item.header"
              :fields="item.fields"
              :data="details"
              :repository="item.repository"
              :relation="item.relation"
            >
            </book-entry-registry-details-card>

            <deferred-details-card
              v-for="(item, k) in bookEntryDetails"
              :key="k"
              :header="item.header"
              :fields="item.fields"
              :beFormBookDetail="beForm"
              :data="details"
              repository="book_entry"
              :lbls="labels"
            >
            </deferred-details-card>

            <b-card header="true" header-tag="header" class="mt-1">
              <div
                slot="header"
                class="d-flex justify-content-between align-items-center"
              >
                <p class="mb-0 d-inline-block align-middle">
                  <b>Recupero Sospesi</b>
                </p>
              </div>
              <div v-if="totalDeferred() != 0">
                <b-row>
                  <div class="col-md-4">
                    <base-datepicker
                      vid="valuta_da"
                      name="Data Recupero Sospesi"
                      label="Data Recupero Sospesi"
                      v-model="form.detail_date"
                      :min="minDate"
                      @select="onDetailDateSelect"
                      :rules="getRules('detail_date', 'entry_detail')"
                    />
                  </div>
                </b-row>
                <entry-details
                  ref="edRef"
                  :details="dtls"
                  v-model="form"
                  :rules="getRules('gross')"
                  :repository="repository"
                  canDelete
                  enableRemainder
                  amountField="byRemainderFunction"
                  :remainderFunction="totalDeferred"
                  @select="
                    selectCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                  @unselect="
                    unselectCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                  @delete="
                    deleteCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                ></entry-details>
                <b-button
                  type="button"
                  variant="lisaweb"
                  class="mb-2"
                  :disabled="
                    invalid ||
                    parseFloat(entryDetailsCreateTotal().toFixed(2)) == 0
                  "
                  @click="handleSubmit(onSubmit)"
                  >Salva</b-button
                >
                <b-button
                  type="button"
                  variant="lisaweb"
                  class="mb-2"
                  :disabled="
                    invalid || totalDeferred() - entryDetailsCreateTotal() == 0
                  "
                  @click="onRebate()"
                  >Chiudi sospeso con abbuono</b-button
                >
              </div>
              <div v-else>
                <b>Non ci sono sospesi</b>
              </div>
            </b-card>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { findAndGet } from "@/utils/transforms";
import FormMixin from "@/mixins/FormMixin";
import ShowMixin from "@/mixins/ShowMixin";
import EntryDetails from "@/components/form/EntryDetails";
import DetailsCard from "@/components/DetailsCard";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import EntryDetailsMixin from "@/mixins/EntryDetailsMixin";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BookEntryRegistryDetailsCard from "@/components/BookEntryRegistryDetailsCard";
import DeferredDetailsCard from "@/components/DeferredDetailsCard";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [ShowMixin, EntryDetailsMixin, FormMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    EntryDetails,
    BaseDatepicker,
    DetailsCard,
    BookEntryRegistryDetailsCard,
    DeferredDetailsCard,
  },
  data() {
    return {
      details: null,
      repository: "book_entry",
      allRepositories: ["book_entry", "registry"],
      mandatoryFields: {
        book_entry: {
          sheet_date: null,
          effective_date: null,
          gross: null,
          net: null,
        },
      },
      defaultLabels: {
        book_entry: {},
        registry: {},
      },
      canEdit: false,
      generalLabels: null,
      entryDetails: [],
      treasuriesCode: [],
      skipTreasuries: ["SS"].concat(this.getExcludedTreasuries()("OTHER")),
      extraTreasuries: ["AB"],
      onlyRealTreasuries: true,
      skipAgencyTreasuries: true, // solo casse di compagnia
      detailFields: {
        book_entry: {
          header: "Movimento Contabile",
          edit: false,
          repository: "book_entry",
          fields: [
            // {
            //   label: this.getDictionary("title", "book_entry"),
            //   value: "title",
            // },
            {
              label: this.getDictionary("book_date", "book_entry"),
              value: "book_date",
              type: "datepicker",
            },
            // {
            //   label: this.getDictionary("insurer_mandate_code", "book_entry"),
            //   value: "mandate_code_id.value",
            //   virtual: (detail) => {
            //     return findAndGet(
            //       this.$store.state.auth.mandate_codes,
            //       "id",
            //       detail.mandate_code_id,
            //       "code"
            //     );
            //   },
            // },
            {
              label: this.getDictionary(
                "insurance_policy_number",
                "book_entry"
              ),
              value: "insurance_policy_number",
              virtual: (item) => {
                return item.various_accountings &&
                  item.various_accountings.length
                  ? item.various_accountings[0].pivot.insurance_policy_number
                  : item.insurance_ancillaries &&
                    item.insurance_ancillaries.length
                  ? item.insurance_ancillaries[0].insurance_policy
                    ? item.insurance_ancillaries[0].insurance_policy.number
                    : ""
                  : "";
              },
            },
          ],
        },
      },
      registryDetailsFields: {
        registry: {
          header: "Anagrafica",
          repository: "registry",
          relation: "correspondence",
          fields: [
            {
              label: this.getDictionary("attribute_NAME_value", "registry"),
              value: "NAME",
              status_registry: 0,
            },
            {
              label: this.getDictionary("attribute_SURN_value", "registry"),
              value: "SURN",
              status_registry: 0,
            },
            {
              label: this.getDictionary("attribute_NINO_value", "registry"),
              value: "NINO",
              status_registry: 0,
            },
            {
              label: this.getDictionary("attribute_DOB_value", "registry"),
              value: "DOB",
              status_registry: 0,
            },
            {
              label: this.getDictionary("attribute_CNAM_value", "registry"),
              value: "CNAM",
              status_registry: 1,
            },
            {
              label: this.getDictionary("attribute_CREG_value", "registry"),
              value: "CREG",
              status_registry: 1,
            },
          ],
        },
      },
      bookEntryDetails: {
        book_entry_premium: {
          header: "Sospeso",
          repository: "book_entry",
          fields: [
            {
              label: this.getDictionary("Stato Sospeso"),
              value: "deferred_status",
            },
            {
              label: this.getDictionary("Premio Totale"),
              value: "gross",
            },
            {
              label: this.getDictionary("Importo originario del sospeso"),
              value: "deferred_pos",
            },
            {
              label: this.getDictionary("Importo incassato"),
              value: "deferred_neg",
            },
            {
              label: this.getDictionary("Importo Abbuono"),
              value: "rebate",
            },
            {
              label: this.getDictionary("Residuo da incassare"),
              value: "total_gross",
            },
          ],
        },
      },
      form: {
        detail_date: moment().format("YYYY-MM-DD"),
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: moment().format("YYYY-MM-DD"),
        },
        book_entries: {
          entry_details: {},
        },
        book_entry: {
          total_gross: 6666.66, // TEST
        },
      },
    };
  },
  methods: {
    toLocaleCurrency,
    moment,
    findAndGet,
    fetch() {
      let queryString = `relations/byRegistry/byTreasury/byBreakdown/byInsurancePolicy`;
      return this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          this.details = data;
          const ed = this.fetchCreateForm(this.rep_entrdtl);
          const be = this.fetchEditForm(this.rep, this.resourceId);
          const re = this.fetchCreateForm("registry");

          Promise.all([ed, be, re]).then(() => {
            this.initDetailCardFormData();
            this.isLoading = false;
          });
        })
        .catch((error) => {
          this.form = {};
          this.isLoading = false;
          console.error(error);
        });
    },
    deleteAll() {
      for (let d of this.dtls.filter((el) => el.selected)) {
        this.deleteCreateEntryDetail(
          this.form.book_entries.entry_details,
          d.id
        );
      }
    },
    totalDeferred() {
      return parseFloat(
        this.details.entry_details
          .filter((el) => el.treasury.code == "SS")
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    entryDetailsCreateTotal() {
      return Object.keys(this.form.book_entries.entry_details)
        .map((key) => {
          return +this.form.book_entries.entry_details[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
    storeEntryDetailPivot(deferredEntryDetailId, relation, payload) {
      const Repo = RepositoryFactory.get("entry_detail");
      return Repo.pivot_store(deferredEntryDetailId, relation, payload);
    },
    storeEntryDetails() {
      let storeEntryDetail = [];

      let entryDetailPayload = {};
      const filteredEntryDetails = Object.values(
        this.form.book_entries.entry_details
      ).filter(
        (entry_detail) =>
          entry_detail.type !== null && entry_detail.amount !== 0
      );

      // Create Payloads
      const ut = moment().unix();
      filteredEntryDetails.forEach((filtered_entry_detail, index) => {
        entryDetailPayload["book_entry_id"] = this.resourceId;

        entryDetailPayload["gross"] = parseFloat(
          filtered_entry_detail.amount.toFixed(2)
        );

        entryDetailPayload["treasury_id"] = filtered_entry_detail.type;

        entryDetailPayload["detail_date"] = this.setEntryDetailDate(
          filtered_entry_detail,
          filteredEntryDetails
        );

        // entryDetailPayload["entry_time"] = String(moment().unix() + index);
        entryDetailPayload["entry_time"] = moment(ut + index).format("x");

        let dtl = this.store(this.rep_entrdtl, entryDetailPayload);
        storeEntryDetail.push(dtl);
      });
      return storeEntryDetail;
    },
    storeDeferredEntryDetail() {
      let storeDeferredEntryDetail = [];

      let deferredEntryDetailPayload = {};

      // Create Payload
      deferredEntryDetailPayload["book_entry_id"] = this.resourceId;

      deferredEntryDetailPayload["treasury_id"] = this.treasuriesCode.find(
        (treasury) => treasury.code === "SS"
      ).id;

      deferredEntryDetailPayload["gross"] = -parseFloat(
        this.entryDetailsCreateTotal().toFixed(2)
      );

      deferredEntryDetailPayload["detail_date"] = this.form.detail_date;

      let dtl = this.store(this.rep_entrdtl, deferredEntryDetailPayload);
      storeDeferredEntryDetail.push(dtl);

      return storeDeferredEntryDetail;
    },
    onRebate() {
      let index = this.dtls.length - 1;
      let rebate_id = this.treasury_options.find(
        (treasury) => treasury.code === "AB"
      ).value;
      this.form.book_entries.entry_details[index].type = rebate_id;
      this.form.book_entries.entry_details[index].amount =
        this.totalDeferred() - this.entryDetailsCreateTotal();
      this.selectCreateEntryDetail(
        this.form.book_entries.entry_details,
        rebate_id,
        index
      );
    },
    onDetailDateSelect(date) {
      // Typed date validity
      if (moment(date, "DD/MM/YYYY").isBefore(this.minDate)) {
        let errMsg = `La data non può essere uguale o precedente a ${toLocaleDate(
          this.minDate
        )}`;
        this.$showSnackbar({
          preset: "info",
          text: `${errMsg}`,
        });
        this.form.detail_date = null;
      }
    },
    checkNegativeTreasury() {
      return (
        Object.values(this.form.book_entries.entry_details).filter(
          (entry_detail) =>
            entry_detail.amount < 0 &&
            this.treasury_options.find(
              (treasury) => treasury.value == entry_detail.type
            ).code !== "AB"
        ).length == 0
      );
    },
    checkEntryDetailsCreateTotal() {
      // Remaining must be equal or greater than zero
      return (
        parseFloat(this.entryDetailsCreateTotal().toFixed(2)) <=
        this.totalDeferred()
      );
    },
    setEntryDetailDate(entry_detail, filteredEntryDetails) {
      let rebate_id = this.treasury_options.find(
        (treasury) => treasury.code === "AB"
      ).value;
      // Select last deferred date if entry detail type is 'abbuono'
      if (
        entry_detail.type === rebate_id &&
        filteredEntryDetails.length === 1
      ) {
        // cerco ultimo sospeso negativo
        let deferred = this.details.entry_details.filter(
          (entry_detail) =>
            entry_detail.treasury.code === "SS" && entry_detail.gross < 0
        );

        console.log("details", this.details.entry_details);

        console.log("deferred", deferred);
        if (deferred.length) {
          return deferred.reduce(
            (a, b) => (a.detail_date > b.detail_date ? a : b).detail_date,
            ""
          );
        } else {
          return this.form.detail_date;
        }
      } else {
        return this.form.detail_date;
      }
    },
    hasRebate() {
      let rebate_id = this.treasury_options.find(
        (treasury) => treasury.code === "AB"
      ).value;

      const found = Object.values(this.form.book_entries.entry_details).find(
        (entry_detail) => entry_detail.type === rebate_id
      );

      return found ? true : false;
    },
    isRebateLast() {
      let rebate_id = this.treasury_options.find(
        (treasury) => treasury.code === "AB"
      ).value;

      const entryDetails = Object.values(
        this.form.book_entries.entry_details
      ).filter(
        (entry_detail) =>
          entry_detail.type !== null && entry_detail.amount !== 0
      );
      return entryDetails[entryDetails.length - 1].type === rebate_id;
    },
    onSubmit() {
      // Check if entry_detail value is negative (only rebate treasury can be negative)
      if (this.checkNegativeTreasury()) {
        // Remaining must be equal or greater than zero
        if (this.checkEntryDetailsCreateTotal()) {
          // If there is at least one 'rebate' treasury then the remaining must be zero
          if (this.hasRebate()) {
            if (!this.isRebateLast()) {
              this.$showSnackbar({
                preset: "error",
                text: `Forma di pagamento 'abbuono' selezionata: l'abbuono deve essere eselezionato come ultima forma di pagamento`,
              });
              return;
            }
            if (
              this.totalDeferred() -
                parseFloat(this.entryDetailsCreateTotal().toFixed(2)) >
              0
            ) {
              this.$showSnackbar({
                preset: "error",
                text: `Forma di pagamento 'abbuono' selezionata: il residuo deve essere azzerato`,
              });
              return;
            }
          }

          // Loading
          this.isLoading = true;

          // Store Entry Details
          let entryDetailsPromises = this.storeEntryDetails();
          let deferredEntryDetailPromise = this.storeDeferredEntryDetail();
          const promises = entryDetailsPromises.concat(
            deferredEntryDetailPromise
          );

          Promise.all(promises)
            .then((response) => {
              let payload = { entry_detail: {} };
              let deferredId = response.find(
                (entry_detail) => entry_detail.data.data.treasury.code === "SS"
              ).data.data.id;
              response
                .filter(
                  (entry_detail) =>
                    entry_detail.data.data.treasury.code !== "SS"
                )
                .forEach(
                  (entry_detail) =>
                    (payload.entry_detail[entry_detail.data.data.id] = {})
                );
              /* Pivot */
              let deferred_pivot = this.storeEntryDetailPivot(
                deferredId,
                "entry_detail",
                payload
              );
              Promise.all([deferred_pivot])
                .then(() => {
                  this.$showSnackbar({
                    preset: "success",
                    text: `Azione Completata: Recupero Sospeso Completato`,
                  });
                  this.deleteAll();
                  this.fetch();
                  this.$emit("fetch");
                  this.isLoading = false;
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: `${errMsg}`,
                  });
                  this.isLoading = false;
                });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.isLoading = false;
            });
        } else {
          this.$showSnackbar({
            preset: "error",
            text: `L'importo dei pagamenti è maggiore dell'importo dei sospesi`,
          });
        }
      } else {
        this.$showSnackbar({
          preset: "error",
          text: `Non puoi inserire importi negativi (esclusi gli abbuoni)`,
        });
      }
    },
    ...mapGetters("auth", {
      getExcludedTreasuries: "excludedTreasuries",
    }),
  },
  computed: {
    rep() {
      return this.repository;
    },
    rep_entrdtl() {
      return "entry_detail";
    },
    minDate() {
      return moment(this.details.book_date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.isLoading = true;
    this.fetch();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
  border: none;
}
:deep(.col-md-4.border-bottom) {
  text-transform: uppercase;
  color: rgb(63, 60, 60);
  font-size: bold;
}
</style>
