<template>
  <b-modal
    id="editEditDetailModal"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
    @ok="close()"
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="pencil"></b-icon>
        <span>Modifica dettaglio</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel(), close()"
      >
        &times;
      </button>
    </template>
    <div class="mt-2">
      <validation-observer ref="observer1" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.prevent="">
          <b-card class="filter">
            <b-row>
              <div class="col-md-4">
                <base-select
                  name="treasury_id"
                  vid="treasury_id"
                  label="Forme di Pagamento"
                  v-model="inputVal.treasury_id"
                  :options="opt_treasuries"
                  :rules="{ required: true }"
                />
              </div>
              <div class="form-group col-md-5">
                <base-currency
                  name="Importo"
                  vid="gross"
                  label="Importo"
                  v-model="inputVal.gross"
                  :options="{
                    currency: 'EUR',
                    locale: 'it-IT',
                    precision: 2,
                    valueRange: {
                      min: 0.0,
                    },
                  }"
                  :rules="{
                    required: true,
                    compare_number: { otherValue: '0', operator: '>' },
                  }"
                />
              </div>
            </b-row>
          </b-card>
          <div class="py-2 mb-4">
            <b-button
              @click="handleSubmit(save)"
              type="button"
              :disabled="invalid"
              variant="lisaweb"
              size="sm"
            >
              Salva
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>
<script>
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      opt_treasuries: [],
    };
  },
  components: {
    BaseCurrency,
    BaseSelect,
  },
  props: {
    value: null,
    initialGross: Number,
  },
  methods: {
    save() {
      this.$emit("save", this.inputVal, this.initialGross);
    },
    close() {
      if (this.initialGross !== this.inputVal.gross) {
        this.inputVal.gross = this.initialGross;
      }
    },
    ...mapGetters("auth", {
      getTreasuries: "allTreasuries",
    }),
  },
  mounted() {
    const skipTreasuries = ["AC", "SS"];
    this.opt_treasuries = this.getTreasuries().filter(
      (treasury) => !skipTreasuries.includes(treasury.code)
    );
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  overflow-y: visible;
}
</style>
