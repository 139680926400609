<template>
  <div class="mb-3">
    <div v-for="(obj, index) in details" :key="'' + index + obj.id">
      <entry-detail
        v-if="inputVal.book_entries.entry_details[obj.id]"
        :name="noName ? '' : name + ' ' + (index + 1)"
        v-model="inputVal.book_entries.entry_details[obj.id]"
        :conf="obj"
        @select="onSelectEntryDetail"
        @unselect="onUnselectEntryDetail"
        @delete="onDeleteEntryDetail"
        :canDelete="canDelete"
        :rules="rules"
      ></entry-detail>
    </div>
    <b-row>
      <div class="col-md-6">
        <b>Totale: {{ toLocaleCurrency(total) }}</b>
      </div>
      <div class="col-md-4">
        <b v-if="enableRemainder">Residuo: {{ toLocaleCurrency(remainder) }}</b>
      </div>
    </b-row>
  </div>
</template>
<script>
import EntryDetail from "@/components/form/EntryDetail";
import { toLocaleCurrency } from "@/utils/strings";
import { computed } from "vue";

export default {
  props: {
    value: undefined,
    name: {
      type: String,
      default: "Forma di Pagamento",
    },
    details: {
      type: Array,
      default() {
        return [];
      },
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    noName: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    enableRemainder: {
      type: Boolean,
      default: false,
    },
    amountField: {
      type: String,
      default: "calculatedGross",
    },
    remainderFunction: null,
    repository: String,
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      canDelete: computed(() => this.canDelete),
    };
  },
  components: {
    EntryDetail,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    total() {
      // devo negativizzare la cassa AC...ma solamene nel Totale, non nel componente EntryDetail
      let id = this.$store.state.auth.treasuries.find(
        (treasury) => treasury.code === "AC"
      ).id;
      // let clone = { ...this.inputVal.book_entries.entry_details };
      let clone = JSON.parse(
        JSON.stringify(this.inputVal.book_entries.entry_details)
      );
      let ac = Object.values(clone).find((e) => e.type == id);
      if (ac) {
        ac.amount *= -1;
      }
      // return Object.keys(this.inputVal.book_entries.entry_details)
      return Object.keys(clone)
        .map((key) => {
          // return +this.inputVal.book_entries.entry_details[key].amount || 0;
          return +clone[key].amount || 0;
        })
        .reduce(function(sum, i) {
          return sum + i;
        }, 0);
    },
    amount() {
      switch (this.amountField) {
        case "calculatedGross":
          return (
            this.inputVal[this.repository].net +
            this.inputVal[this.repository].tax
          );
        case "gross":
          return this.inputVal[this.repository].gross;
        case "net":
          return this.inputVal[this.repository].net;
        case "byRemainderFunction":
          return this.remainderFunction();
      }
      return 0;
    },
    remainder() {
      return this.amount - this.total;
    },
  },
  methods: {
    toLocaleCurrency,
    onSelectEntryDetail(selectedOption, id) {
      this.$emit("select", selectedOption, id);
    },
    onUnselectEntryDetail(removedOption, id) {
      this.$emit("unselect", removedOption, id);
    },
    onDeleteEntryDetail(id) {
      this.$emit("delete", id);
    },
  },
};
</script>
