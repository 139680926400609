<template>
  <div class="mt-1 mx-3">
    <deposit></deposit>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Deposit from "@/components/accounting/deposit/index.vue";

export default {
  props: {},
  components: { Deposit },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Contabilità",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Acconti",
      path: "book_entries.index2",
      level: 2,
    });
  },
};
</script>
