<template>
  <div class="mt-1 mx-3">
    <cooperators></cooperators>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Cooperators from "@/components/accounting/cooperators/index.vue";

export default {
  props: {},
  components: { Cooperators },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Contabilità",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Collaborazioni",
      path: "book_entries.index3",
      level: 2,
    });
  },
};
</script>
