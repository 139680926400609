<template>
  <b-modal
    id="quickSearchRegistryGroupModal"
    size="lg"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="people-fill"></b-icon>
        <span>Cerca Gruppi</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>

    <b-form @submit.prevent="onSearch(filterName)">
      <b-row>
        <div class="col-md-4">
          <base-input
            name="title"
            type="text"
            label="Descrizione"
            v-model="filter.byAttribute.title"
            placeholder="Inserisci una descrizione"
          />
        </div>
        <div class="col-md-4">
          <base-input
            name="code"
            type="text"
            label="Codice"
            v-model="filter.byAttribute.code"
            placeholder="Inserisci un codice"
          />
        </div>
        <div class="col-md-4 align-self-center actions-wrapper">
          <b-button type="submit" variant="lisaweb" size="sm">Cerca</b-button>
          <b-button
            class="btn-reset"
            type="button"
            variant="lisaweb"
            size="sm"
            @click="onClearFilter(filterName)"
            >Reset</b-button
          >
        </div>
      </b-row>
    </b-form>
    <registry-groups
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: ['byCorrespondence'],
      }"
      :ref="tableRef"
      noInnerWidth
      noActions
      noCustom
      includeRowSelector
      @rowSelector="onModalSelector"
    >
    </registry-groups>
  </b-modal>
</template>
<script>
import BaseInput from "@/components/form/BaseInput";
// import RegistryGroups from "@/components/tables/RegistryGroups";
import { mapActions } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterQuickSearchRegistryGroup",
      repository: "registry_group",
      resource: "registry_groups",
      tableRef: "QuickSearchRegistryGroupTableRef",
      fields: [
        {
          key: "actionRow",
          label: "Azioni",
          thStyle: {
            width: "20px",
          },
        },
        {
          key: "title",
          label: "Descrizione",
        },
        {
          key: "code",
          label: "Codice",
        },
      ],
    };
  },
  components: {
    RegistryGroups: () => import("@/components/tables/RegistryGroups"),
    // RegistryGroups,
    BaseInput,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
          code: null,
        },
      };
      return init;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      // this.onSearch(name);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onModalSelector(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style scoped>
.actions-wrapper {
  margin-top: 5px;
}
</style>
