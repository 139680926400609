<template>
  <b-row>
    <div class="col-md-12" v-if="name">
      <strong class="inner-title">{{ name }}</strong>
    </div>
    <!-- seleziona forma di pagamento -->
    <div class="col-md-6">
      <base-select
        :id="conf.id"
        :vid="'select' + conf.id"
        name="type"
        label="Tipo Cassa"
        v-model="inputVal.type"
        :options="conf.options"
        @select="onSelect"
        @remove="onRemove"
      />
    </div>
    <!-- ammontare -->
    <div class="col-md-4">
      <base-currency
        :id="'bc-' + conf.id"
        :vid="'currency' + conf.id"
        name="amount"
        label="Importo"
        v-model="inputVal.amount"
        :options="{ currency: 'EUR', locale: 'it-IT' }"
        placeholder="Inserisci un importo"
        :disabled="!inputVal.type"
        :rules="inputVal.type ? rules : {}"
      />
    </div>
    <div
      class="form-group col-md-2 align-self-end"
      v-if="inputVal.type && canDelete"
    >
      <b-button
        size="sm"
        variant="lisaweb"
        class="mt-1 mr-1"
        title="Rimuovi"
        @click="onDelete(conf.id)"
      >
        <b-icon icon="trash"></b-icon>
      </b-button>
    </div>
  </b-row>
</template>
<script>
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseSelect from "@/components/form/BaseSelect";
export default {
  inject: ["canDelete"],
  props: {
    value: undefined,
    name: String,
    conf: Object,
    // canDelete: {
    //   type: Boolean,
    //   default: false,
    // },
    rules: Object,
  },
  components: {
    BaseCurrency,
    BaseSelect,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {},
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onSelect(selectedOption, id) {
      // console.debug("entryDetail select onSelect", selectedOption, id);
      this.$emit("select", selectedOption, id);
    },
    onRemove(removedOption, id) {
      // console.debug("entryDetail select onRemove", removedOption, id);
      this.$emit("unselect", removedOption, id);
    },
    onDelete(id) {
      this.$emit("delete", id);
    },
  },
};
</script>
<style scoped lang="scss">
.inner-title {
  display: block;
  font-weight: bold;
  margin: 0 0 5px;
}
</style>
