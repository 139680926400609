var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"editEditDetailModal","size":"md","ok-only":"","ok-title":"Chiudi","ok-variant":"lisaweb","header-bg-variant":"lisaweb","no-close-on-esc":"","no-close-on-backdrop":""},on:{"ok":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"modal-header",fn:function({ cancel }){return [_c('div',{staticClass:"modal-title",attrs:{"slot":"modal-title"},slot:"modal-title"},[_c('b-icon',{attrs:{"icon":"pencil"}}),_vm._v(" "),_c('span',[_vm._v("Modifica dettaglio")])],1),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","title":"Chiudi","data-dismiss":"modal"},on:{"click":function($event){cancel(), _vm.close()}}},[_vm._v("\n      ×\n    ")])]}}])},[_vm._v(" "),_c('div',{staticClass:"mt-2"},[_c('validation-observer',{ref:"observer1",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, invalid }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',{staticClass:"filter"},[_c('b-row',[_c('div',{staticClass:"col-md-4"},[_c('base-select',{attrs:{"name":"treasury_id","vid":"treasury_id","label":"Forme di Pagamento","options":_vm.opt_treasuries,"rules":{ required: true }},model:{value:(_vm.inputVal.treasury_id),callback:function ($$v) {_vm.$set(_vm.inputVal, "treasury_id", $$v)},expression:"inputVal.treasury_id"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-5"},[_c('base-currency',{attrs:{"name":"Importo","vid":"gross","label":"Importo","options":{
                  currency: 'EUR',
                  locale: 'it-IT',
                  precision: 2,
                  valueRange: {
                    min: 0.0,
                  },
                },"rules":{
                  required: true,
                  compare_number: { otherValue: '0', operator: '>' },
                }},model:{value:(_vm.inputVal.gross),callback:function ($$v) {_vm.$set(_vm.inputVal, "gross", $$v)},expression:"inputVal.gross"}})],1)])],1),_vm._v(" "),_c('div',{staticClass:"py-2 mb-4"},[_c('b-button',{attrs:{"type":"button","disabled":invalid,"variant":"lisaweb","size":"sm"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v("\n            Salva\n          ")])],1)],1)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }