<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <deposited-cash-entries
          :fields="fields"
          :repository="repository"
          :resource="resource"
          @edit="onEdit"
          @destroy="onDestroy"
          :filterName="filterName"
          :ref="tableRef"
          :onlyActions="['destroy', 'edit']"
          :filterOn="{
            byRelations: ['byCashEntryHistory'],
          }"
          noInnerWidth
          noPagination
          noInputPaginator
        >
        </deposited-cash-entries>
        <edit-deposited-cash-entry-modal
          @save="onUpdateEntryDetail"
          v-model="item"
        ></edit-deposited-cash-entry-modal>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { toLocaleCurrency } from "@/utils/strings";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import EditDepositedCashEntryModal from "@/components/modals/editDepositedCashEntryModal";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import moment from "moment";
moment.locale("it");
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [ShowMixin, FormMixin, DetailTabAutofetchTableMixin],
  name: "CashEntryDeposited",
  props: {
    resourceId: Number,
  },
  data() {
    return {
      filter: this.initFilter(),
      isLoading: false,
      details: [],
      detail: null,
      item: null,
      initial_gross: null,
      filterName: "filterModalCashEntryDepositedDetail",
      repository: "cash_entry",
      resource: "cash_entries",
      tableRef: "modalDepositedTable",
      args: {},
      fields: [
        {
          key: "treasury_description",
          label: "Conto",
        },
        {
          key: "cash_time",
          label: "Data Acconto",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "related_description",
          label: "Descrizione",
        },
        {
          key: "gross",
          label: this.getDictionary("Importo", "treasury"),
          sortKey: "gross",
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
      ],
    };
  },
  methods: {
    initFilter() {
      let init = {
        byCashEntry: {
          id: this.resourceId,
        },
        byDeposited: "both",
      };
      return init;
    },
    onEdit(id, item) {
      this.initial_gross = item.gross;
      this.item = JSON.parse(JSON.stringify(item));
      this.$bvModal.show("editDepositedCashEntryModal");
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          let criteria = this.filter;
          let name = this.filterName;
          this.saveFilterByName({ name, criteria });
          this.$refs[this.tableRef].fetch().then(() => {
            this.$emit("fetch");
            this.$showSnackbar({
              preset: "success",
              text: `Acconto Modificato con successo`,
            });
            this.removeFilterByName(name);
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onUpdateEntryDetail(form) {
      if (
        Math.abs(form.gross - this.initial_gross) <=
        this.detail.gross + this.detail.reduced
      ) {
        const payload = {
          broker_id: form.broker_id,
          cash_entry_id: form.cash_entry_id,
          cash_reference_id: form.cash_reference_id,
          cash_time: toLocaleDate(form.cash_time, "YYYY-MM-DD HH:mm:ss"),
          gross: -Math.abs(form.gross),
          title: form.title,
          treasury_id: form.treasury_id,
        };
        const Repo = RepositoryFactory.get(this.repository);
        Repo.update(form.id, payload)
          .then(() => {
            let criteria = this.filter;
            let name = this.filterName;
            this.saveFilterByName({ name, criteria });
            this.$refs[this.tableRef].fetch().then(() => {
              this.$emit("fetch");
              this.$showSnackbar({
                preset: "success",
                text: `Acconto Modificato con successo`,
              });
              this.removeFilterByName(name);
            });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
      } else {
        this.$showSnackbar({
          preset: "success",
          text: "L'importo inserito supera il residuo disponibile",
        });
      }
    },
    depositedMapping(data) {
      this.detail = data[0];
      let details = data[0]?.cash_histories ? data[0]?.cash_histories : [];
      details.forEach((detail) => {
        detail.gross = Math.abs(detail.gross);
        detail.related_description = this.detail.title;
      });
      return details;
    },
  },
  beforeMount() {
    this.args = { items: { handle: this.depositedMapping } };
  },
  components: {
    BaseIcon,
    EditDepositedCashEntryModal,
    DepositedCashEntries: () =>
      import("@/components/tables/ReducedDepositedCashEntry"),
  },
  computed: {
    book_entry() {
      return "book_entry";
    },
  },
};
</script>
