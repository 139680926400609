var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"center":"","show":_vm.isLoading,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center mt-5"},[_c('base-icon',{attrs:{"name":"loading","width":"35","height":"35"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Operazione in corso...")])],1)]},proxy:true}])},[(!_vm.isLoading)?_c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, handleSubmit }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_vm._l((_vm.detailFields),function(item,index){return _c('details-card',{key:index,attrs:{"header":item.header,"edit":item.edit || false,"fields":item.fields,"beForm":_vm.beForm,"data":_vm.details,"repository":item.repository}})}),_vm._v(" "),_c('b-card',{staticClass:"mt-1",attrs:{"header":"true","header-tag":"header"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('p',{staticClass:"mb-0 d-inline-block align-middle"},[_c('b',[_vm._v("Recupero Acconto")])])]),_vm._v(" "),(_vm.totalDepositedPos() + _vm.totalDepositedNeg() != 0)?_c('div',[_c('b-row',[_c('div',{staticClass:"col-md-4"},[_c('base-datepicker',{attrs:{"vid":"valuta_da","name":"Data Recupero Acconto","label":"Data Recupero Acconto","min":_vm.minDate,"rules":_vm.getRules('detail_date', 'entry_detail')},on:{"select":_vm.onDetailDateSelect},model:{value:(_vm.form.detail_date),callback:function ($$v) {_vm.$set(_vm.form, "detail_date", $$v)},expression:"form.detail_date"}})],1)]),_vm._v(" "),_c('entry-details',{ref:"edRef",attrs:{"details":_vm.dtls,"rules":_vm.getRules('gross'),"repository":_vm.repository,"canDelete":"","enableRemainder":"","amountField":"byRemainderFunction","remainderFunction":_vm.totalDeposited},on:{"select":function($event){return _vm.selectCreateEntryDetail(
                    _vm.form.book_entries.entry_details,
                    ...arguments
                  )},"unselect":function($event){return _vm.unselectCreateEntryDetail(
                    _vm.form.book_entries.entry_details,
                    ...arguments
                  )},"delete":function($event){return _vm.deleteCreateEntryDetail(
                    _vm.form.book_entries.entry_details,
                    ...arguments
                  )}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_vm._v(" "),_c('b-button',{staticClass:"mb-2",attrs:{"type":"button","variant":"lisaweb","disabled":invalid || _vm.entryDetailsCreateTotal() == 0},on:{"click":function($event){return handleSubmit(_vm.onSubmit)}}},[_vm._v("Salva")]),_vm._v(" "),_c('b-button',{staticClass:"mb-2",attrs:{"type":"button","variant":"lisaweb","disabled":invalid ||
                  -_vm.entryDetailsCreateTotal() + _vm.totalDeposited() <= 0},on:{"click":function($event){return _vm.onRebate()}}},[_vm._v("Chiudi acconto con abbuono")])],1):_c('div',[_c('b',[_vm._v("Non ci sono acconti")])])])],2)]}}],null,false,303239405)})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }