<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <entry-details
          :fields="fields"
          :repository="repository"
          :resource="resource"
          @edit="onEdit"
          @destroy="onDestroy"
          :filterName="filterName"
          :ref="tableRef"
          :onlyActions="['destroy', 'edit']"
          noInnerWidth
          sortField="entry_time"
          noPagination
          noInputPaginator
        >
        </entry-details>
        <edit-entry-detail-modal
          @save="onUpdateEntryDetail"
          v-model="detail"
          :initialGross="initial_gross"
        ></edit-entry-detail-modal>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import EntryDetails from "@/components/tables/EntryDetails";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import EditEntryDetailModal from "@/components/modals/editEntryDetailModal";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [ShowMixin, FormMixin, DetailTabAutofetchTableMixin],
  name: "Deferred",
  props: {
    resourceId: Number,
  },
  data() {
    return {
      filter: this.initFilter(),
      isLoading: false,
      details: [],
      detail: null,
      initial_gross: null,
      filterName: "filterModalDeferredDetail",
      repository: "entry_detail",
      resource: "entry_details",
      tableRef: "modalDeferredTable",
      deferredCode: "SS",
      args: {},
      // perPage: 0, // no pagination on tab auto fetch
      fields: [
        {
          key: "treasury.title",
          label: "Cassa",
        },
        {
          key: "gross",
          label: this.getDictionary("Importo", "treasury"),
          sortable: true,
          sortKey: "gross",
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "detail_date",
          label: this.getDictionary("Data", "treasury"),
          sortable: true,
          sortKey: "detail_date",
          formatter: (value) => toLocaleDate(value),
        },
      ],
      form: {
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
      },
    };
  },
  methods: {
    initFilter() {
      let init = {
        byBookEntry: { id: this.resourceId },
      };
      return init;
    },
    onEdit(entry_detail_id) {
      console.log(this.$refs[this.tableRef]);
      const entry_detail = this.details.find(
        (entry_detail) => entry_detail.id == entry_detail_id
      );
      this.detail = entry_detail;
      this.initial_gross = this.$refs[this.tableRef].items.find(
        (item) => item.id === entry_detail_id
      ).gross;
      this.$bvModal.show("editEditDetailModal");
    },
    deleteEntryDetail(id) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.destroy(id);
    },
    updateEntryDetail(id, form) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.update(id, form);
    },
    onDestroy(id) {
      // Item
      const deletedItem = this.$refs[this.tableRef].items.find(
        (item) => item.id === id
      );
      // Related deferred entry detail
      const relatedDeferredItem = this.details.find(
        (detail) => detail.id === deletedItem.pivot.entry_detail_id
      );

      const promises = [];

      // Delete entry detail
      promises.push(this.deleteEntryDetail(deletedItem.id));

      // Delete or update related deferred entry detail
      if (relatedDeferredItem.gross + deletedItem.gross === 0) {
        promises.push(this.deleteEntryDetail(relatedDeferredItem.id));
      } else {
        const payload = {
          book_entry_id: relatedDeferredItem.book_entry_id,
          treasury_id: relatedDeferredItem.treasury_id,
          gross: relatedDeferredItem.gross + deletedItem.gross,
          detail_date: relatedDeferredItem.detail_date,
        };
        promises.push(this.updateEntryDetail(relatedDeferredItem.id, payload));
      }

      Promise.all(promises)
        .then(() => {
          let criteria = this.filter;
          let name = this.filterName;
          this.saveFilterByName({ name, criteria });
          this.$refs[this.tableRef]
            .fetch()
            .then(() => {
              this.$emit("fetch");
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Recupero Sospeso Eliminato`,
              });
              this.removeFilterByName(name);
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({ preset: "error", text: errMsg });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onUpdateEntryDetail(detail, initialGross) {
      const diff = parseFloat(
        initialGross.toFixed(2) - detail.gross.toFixed(2)
      );

      if (diff < 0 && this.totalDeferred(this.details) + diff < 0) {
        this.$showSnackbar({
          preset: "error",
          text: `L'importo inserito eccede il sospeso residuo`,
        });
      } else {
        // Close edit modal
        this.$bvModal.hide("editEditDetailModal");
        // Related Deferred Item
        const relatedDeferredId = this.$refs[this.tableRef].items.find(
          (item) => item.id === this.detail.id
        ).pivot.entry_detail_id;
        const relatedDeferredItem = this.details.find(
          (item) => item.id === relatedDeferredId
        );
        const promises = [];
        // Update entry detail
        const entryDetailPayload = {
          book_entry_id: detail.book_entry_id,
          treasury_id: detail.treasury_id,
          gross: detail.gross,
          detail_date: detail.detail_date,
        };
        promises.push(this.updateEntryDetail(detail.id, entryDetailPayload));
        // Update related deferred entry detail
        const deferredPayload = {
          book_entry_id: relatedDeferredItem.book_entry_id,
          treasury_id: relatedDeferredItem.treasury_id,
          gross: relatedDeferredItem.gross + (initialGross - detail.gross),
          detail_date: relatedDeferredItem.detail_date,
        };
        promises.push(
          this.updateEntryDetail(relatedDeferredItem.id, deferredPayload)
        );
        Promise.all(promises)
          .then(() => {
            let criteria = this.filter;
            let name = this.filterName;
            this.saveFilterByName({ name, criteria });
            this.$refs[this.tableRef]
              .fetch()
              .then(() => {
                this.$emit("fetch");
                this.$showSnackbar({
                  preset: "success",
                  text: `Azione Completata: Recupero Sospeso Aggiornato`,
                });
                this.removeFilterByName(name);
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({ preset: "error", text: errMsg });
              });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
      }
    },
    toCurrency(value) {
      if (!value) value = "0.00001";
      return toLocaleCurrency(parseFloat(value));
    },
    deferredMapping(data) {
      this.details = data;
      let details = [];
      data.filter((item) => {
        if (
          item.treasury.code === "SS" &&
          item.treasury.sector.is_abstract.value === "N" &&
          item.gross < 0 &&
          moment(item.detail_date).isSameOrAfter(item.book_entry.book_date) &&
          item.entry_details.length
        ) {
          item.entry_details.forEach((entry_detail) =>
            details.push(entry_detail)
          );
        }
      });
      // entry_time è una stringa, uso localeCompare
      return details.sort((a, b) => a.entry_time.localeCompare(b.entry_time));
    },
    totalDeferred(data) {
      return parseFloat(
        data
          .filter((el) => el.treasury.code == "SS")
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
  },
  beforeMount() {
    this.args = { items: { handle: this.deferredMapping } };
  },
  components: {
    BaseIcon,
    EntryDetails,
    EditEntryDetailModal,
  },
  computed: {
    book_entry() {
      return "book_entry";
    },
  },
};
</script>
