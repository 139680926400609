// import { showSnackbar } from "@/utils/messages";
import { mapGetters } from "vuex";
// import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  data: () => ({
    skipAgencyTreasuries: false,
    onlyRealTreasuries: false,
    skipTreasuries: [],
    extraTreasuries: [],
    skipDefault: ["AC"],
    treasury_options: [],
    treasuriesCode: [],
    enableEditEntryDetailsComp: false, // show/hide the edit comp
    entryDetailsCompEditableConf: {}, // user's operations
    editEntryDetailsCompToggle: false, // sync by toggle button Modifica/Annulla
    deletableEntryDetailIds: [], // list of ids to be removed by the submit
  }),
  beforeMount() {
    this.setTreasuryCodes();
    this.$set(this, "treasury_options", this.getTreasuryOptions());
    this.initCreateEntryDetail(this.form.book_entries.entry_details);
  },
  methods: {
    ...mapGetters("auth", {
      getTreasuries: "treasuries",
    }),
    addCreateEntryDetailsComp(form) {
      // 10/08/2022
      // let options = this.treasury_options;
      // #559
      this.$set(this, "treasury_options", this.getTreasuryOptions());
      let n = this.dtls.length;
      // there are already n rows...
      if (n === this.treasury_options.length) {
        // ...cannot add any more items
        return;
      }
      // there's already a new treasury with blank amount...

      // WARNING: 10/08/2022 id non è indicatore di chiave per form
      // questo check non è consistente e potrebbe andare in errore
      // console.log("entryDetails: ", this.entryDetails);
      if (
        Object.keys(this.dtls).filter((x) => {
          let id = this.dtls[x].id;
          return form[id].type === null;
        }).length > 0
      ) {
        // ...cannot add any more items
        return;
      }
      // range ids
      let rangeIds = Array.from(
        { length: this.treasury_options.length },
        (v, k) => k
      );
      // get the already used ids
      let usedIds = this.dtls.map((f) => f.id);
      // get the ids not already used
      let freeIds = rangeIds.filter((id) => !usedIds.includes(id));

      let id = freeIds.reverse().pop();
      this.dtls.push({
        id: id, // later, set as the id of the select component named "type"
        selected: null, // later, will keep the selected value (from the select component named "type")
        options: this.treasury_options, // later, set as otpions of the select component named "type"
      });
    },
    addEditEntryDetailsComp(form) {
      let n = Object.keys(this.dtlscfg).length;
      // there are already n rows...
      if (n === this.treasury_options.length) {
        // ...cannot add any more items
        return;
      }
      // there's already a new treasury with blank amount...
      if (
        Object.keys(this.dtlscfg).filter((x) => {
          let id = this.dtlscfg[x].id;
          return form[id].type === null;
        }).length > 0
      ) {
        // ...cannot add any more items
        return;
      }
      let options = this.treasury_options;
      // filter treasury_options removing the ones already selected (eg looping in this.form.book_entries.entry_details[formaN].type)
      // options = this.treasury_options.filter(
      //   (elem) =>
      //     !Object.keys(this.form2.book_entries.entry_details)
      //       .map((f) => this.form2.book_entries.entry_details[f].type)
      //       .includes(elem.value)
      // );
      // NOTE: newIndex must be really a new one: get the max id among those starting with "NewIndex-".
      // Cannot simply rely on length + 1 !!!
      let newIndex =
        parseInt(
          Object.keys(this.dtlscfg)
            .filter((c) => c.startsWith("NewIndex-"))
            .reduce(function (prev, current) {
              return parseInt(prev.slice(9)) > parseInt(current.slice(9))
                ? prev.slice(9)
                : current.slice(9);
            }, "0")
        ) + 1;
      // if not already configured...
      if (!Object.keys(this.dtlscfg).includes(`NewIndex-${newIndex}`)) {
        this.dtlscfg[`NewIndex-${newIndex}`] = {
          id: `NewIndex-${newIndex}`, // later, set as the id of the select component named "type"
          selected: null, // later, will keep the selected value (from the select component named "type")
          options: options, // later, set as options of the select component named "type"
        };
      }
      if (!Object.keys(form).includes(`NewIndex-${newIndex}`)) {
        this.$set(form, `NewIndex-${newIndex}`, {});
        this.$set(form[`NewIndex-${newIndex}`], "amount", null);
        this.$set(form[`NewIndex-${newIndex}`], "type", null);
      }
    },
    selectCreateEntryDetail(form, selected, id) {
      let foundIndex;
      // if already selected among all entryDetails, stop!
      foundIndex = this.entryDetails.findIndex((x) => x.selected == selected);
      if (foundIndex !== -1) {
        // Match the treasure text starting from the value (selected), if no result, fallback to the selected value
        const selected_treasure = this.treasury_options
            ? this.treasury_options.filter((el) => el.value == selected)
            : null,
          selected_treasure_txt =
            selected_treasure &&
            selected_treasure[0] &&
            selected_treasure[0].text
              ? selected_treasure[0].text
              : selected;
        this.$showSnackbar({
          preset: "info",
          text: `Attenzione: forma di pagamento "${selected_treasure_txt}" già selezionata`,
          showAction: true,
          actionText: "Rimuovi",
          duration: 5000,
          onActionClick: () =>
            this.unselectCreateEntryDetail(form, selected, id),
        });
      }
      // set the selected prop on this.entryDetails
      foundIndex = this.entryDetails.findIndex((x) => x.id == id);
      if (foundIndex !== -1) {
        this.entryDetails[foundIndex].selected = selected;
        // imposta il remainder
        if (this.$refs["edRef"].enableRemainder) {
          const remainder = this.$refs["edRef"].remainder;
          if (!form[id].amount) {
            // evita che se cambio la tendina mette 0, poi se la ricambio mette remainder
            this.$set(form[id], "amount", remainder);
          }
        }
        // aggiungo una nuova forma di pagamento
        this.$nextTick(() => {
          // devo dargli il tempo...
          this.addCreateEntryDetailsComp(form);
        });
      }
    },
    selectEditEntryDetail(form, selected, id) {
      let foundIndex;
      // if already selected among all entryDetails, stop!
      foundIndex = Object.keys(this.dtlscfg).findIndex(
        (x) => this.dtlscfg[x].selected == selected
      );
      if (foundIndex !== -1) {
        // Match the treasure text starting from the value (selected), if no result, fallback to the selected value
        const selected_treasure = this.treasury_options
            ? this.treasury_options.filter((el) => el.value == selected)
            : null,
          selected_treasure_txt =
            selected_treasure &&
            selected_treasure[0] &&
            selected_treasure[0].text
              ? selected_treasure[0].text
              : selected;
        this.$showSnackbar({
          preset: "info",
          text: `Attenzione: forma di pagamento "${selected_treasure_txt}" già selezionata`,
          showAction: true,
          actionText: "Rimuovi",
          duration: 5000,
          onActionClick: () => this.unselectEditEntryDetail(form, selected, id),
        });
      }
      // set the selected prop on this.entryDetails
      foundIndex = Object.keys(this.dtlscfg).findIndex(
        (x) => this.dtlscfg[x].id == id
      );
      if (foundIndex !== -1) {
        this.dtlscfg[id].selected = selected;
        // imposta il remainder
        if (this.$refs["edRef"].enableRemainder) {
          const remainder = this.$refs["edRef"].remainder;
          if (!form[id].amount) {
            // evita che se cambio la tendina mette 0, poi se la ricambio mette remainder
            this.$set(form[id], "amount", remainder);
          }
        }
        // aggiungo una nuova forma di pagamento
        this.$nextTick(() => {
          this.addEditEntryDetailsComp(form);
        });
      }
    },
    deleteCreateEntryDetail(form, id) {
      // retrieve the removed value (the BaseSelect selected option related to this id), then call unselectTreasury
      let removed = this.dtls.find((t) => t.id === id).selected;
      this.unselectCreateEntryDetail(form, removed, id);
    },
    deleteEditEntryDetail(form, id) {
      console.log("deleteEditEntryDetail form", form);
      console.log("deleteEditEntryDetail id", id);
      // add to this.deletableEntryDetailIds if the id is a valid id (not the string starting with 'NewIndex-...)
      if (!isNaN(parseInt(id))) {
        if (!this.deletableEntryDetailIds.includes(id)) {
          this.deletableEntryDetailIds.push(id);
        }
      }
      delete this.dtlscfg[id];
      this.$delete(form, id);
      this.$nextTick(() => {
        // devo dargli il tempo...
        this.addEditEntryDetailsComp(form);
      });
    },
    unselectCreateEntryDetail(form, removed, id) {
      this.$set(form[id], "amount", null);
      this.$set(form[id], "type", null);
      // remove all then reset this.entryDetails by pushing back again all but the one removed
      let entryDetails = [];
      let selected = [];

      for (let i = 0; i < Object.keys(form).length; i++) {
        if (
          form[i].type /* && this.form.book_entries.entry_details[i].amount*/
        ) {
          let options = this.treasury_options.filter(
            (elem) => !selected.includes(elem.value)
          );
          selected.push(form[i].type);
          // selected.push(removed)
          entryDetails.push({
            id: i,
            selected: form[i].type,
            options: options,
          });
          // imposta il remainder
          if (this.$refs["edRef"] && this.$refs["edRef"].enableRemainder) {
            console.log("OK");

            const remainder = this.$refs["edRef"].remainder;
            if (!form[i].amount) {
              // evita che se cambio la tendina mette 0, poi se la ricambio mette remainder
              this.$set(form[i], "amount", remainder);
            }
          }
        }
      }
      // update the array for drawing v-for loop
      this.dtls = entryDetails;
      this.addCreateEntryDetailsComp(form);
    },
    unselectEditEntryDetail(form, removed, id) {
      this.$set(form[id], "type", null);
      this.deleteEditEntryDetail(form, id);
    },
    initCreateEntryDetail(form /* , hasNegativeGross = false */) {
      /* // if gross is negative...(CREATE MODE)
      if (hasNegativeGross) {
        console.debug(
          "EDMixin changeEntryDetails: negative gross, can add AC treasury"
        );
        this.extraTreasuries.push("AC");
        this.$set(this, "skipDefault", []); // or AC will be skipped
      } */

      this.$set(this, "treasury_options", this.getTreasuryOptions());
      for (let i = 0; i < this.treasury_options.length; i++) {
        this.$set(form, i, {});
        this.$set(form[i], "amount", null);
        this.$set(form[i], "type", null);
      }
      this.addCreateEntryDetailsComp(form);
    },
    setTreasuryCodes() {
      const data = this.$store.state.auth.treasuries;
      this.treasuriesCode = data.map((el) => {
        return {
          id: el.id,
          code: el.code,
        };
      });
    },
    getTreasuryOptions() {
      // let queryString = "perPage=0";
      // const Repo = RepositoryFactory.get("treasury");
      // Repo.index(queryString).then((response) => {
      // const data = response.data.data;
      const data = this.$store.state.auth.treasuries;
      let options = [];

      let extraMap = this.extraTreasuries.map((e) => {
        const t = data.find((t) => t.code === e);
        return {
          value: t.id,
          text: `${t.display} - ${t.title}`,
          code: `${t.code}`,
        };
      });

      if (this.onlyRealTreasuries) {
        options = data
          .filter(
            (e) =>
              e.sector.is_abstract.value === "N" &&
              e.sector.is_system.value === "N" &&
              !this.skipTreasuries.concat(this.skipDefault).includes(e.code) // #1022
          )
          .map((e) => ({
            value: e.id,
            text: `${e.display} - ${e.title}`,
            code: `${e.code}`,
          }));
        // .concat(extraMap);
      } else {
        options = data
          .filter(
            (e) =>
              !this.skipTreasuries.concat(this.skipDefault).includes(e.code)
          )
          .map((e) => ({
            value: e.id,
            text: `${e.display} - ${e.title}`,
            code: `${e.code}`,
          }));
        // .concat(extraMap);
      }

      if (this.skipAgencyTreasuries) {
        let allAgencyTreasuries = data
          .filter((e) => e.sector.is_agency.value === "Y")
          .map((e) => e.id);
        options = options.filter((e) => !allAgencyTreasuries.includes(e.value));
      }

      // se non già inclusi...
      for (const opt of extraMap) {
        if (!options.find((e) => e.value === opt.value)) {
          options.push(opt);
        }
      }
      return options;
    },
    // EDIT TREASURIES
    changeEntryDetails(form) {
      // reset the edit conf
      let tmpConf = {};
      // reset deletableEntryDetailIds
      this.deletableEntryDetailIds = [];
      // reset the details, if any
      const length = Object.keys(form).length;
      if (length) {
        const keys = Object.keys(form);
        for (let i = 0; i < length; i++) {
          let key = keys[i];
          this.$delete(form, key);
        }
      }
      // if gross is negative... (EDIT MODE)
      if (this.details && this.details.gross < 0) {
        console.debug(
          "EDMixin changeEntryDetails: negative gross, can add AC treasury"
        );
        this.extraTreasuries.push("AC");
        this.$set(this, "skipDefault", []); // or AC will be skipped
      }
      // // NEW 10/08/2022
      // this.initCreateEntryDetail(form.book_entries.entry_details);

      this.$set(this, "treasury_options", this.getTreasuryOptions());

      // set the model for all details
      for (const detail of this.entryDetails || []) {
        // skip this.skipTreasuries and this.skipDefault treasuries
        if (
          this.skipTreasuries
            .concat(this.skipDefault)
            .includes(detail.treasury.code)
        ) {
          continue;
        }
        // NEW
        this.$set(form, `${detail.id}`, {});
        this.$set(form[detail.id], "amount", detail.gross);
        this.$set(form[detail.id], "type", detail.treasury.id);

        tmpConf[detail.id] = {
          id: detail.id, // later, set as the id of the select component named "type"
          selected: detail.treasury.id, // later, will keep the selected value (from the select component named "type")
          options: this.treasury_options, // later, set as options of the select component named "type"
        };
      }
      // init the edit details list
      this.dtlscfg = tmpConf;
      if (this.editEntryDetailsCompToggle) {
        // aggiungo una nuova forma di pagamento
        console.debug("!!! changeEntryDetails: aggunto dettaglio vuoto !!!");
        this.$nextTick(() => {
          // devo dargli il tempo...
          this.addEditEntryDetailsComp(form);
        });
      }
    },
  },
  computed: {
    dtls: {
      set(val) {
        this.entryDetails = val;
      },
      get() {
        return this.entryDetails;
      },
    },
    dtlscfg: {
      set(val) {
        this.entryDetailsCompEditableConf = val;
      },
      get() {
        return this.entryDetailsCompEditableConf;
      },
    },
  },
};
